import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SplashScreen.css"; 

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/home");
    }, 5000);
  }, [navigate]);

  return (
    <div className="splash-container">
      {/* MP4 Video */}
      <video autoPlay loop muted className="splash-video">
        <source src="/assets/bgsplash.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

    </div>
  );
};

export default SplashScreen;
