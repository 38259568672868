import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./VoteForm.css";
import { FaTimes } from "react-icons/fa";

const VoteForm = ({ onClose }) => {
  const navigate = useNavigate();
  const totalSteps = 4;
  const [step, setStep] = useState(1);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [cities, setCities] = useState([]);
  const [cityData, setCityData] = useState(null);
  const [cityProducts, setCityProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [formData, setFormData] = useState({
    dishName: "",
    restaurant: "",
    selectedCategory: "",
    selectedFlavor: "",
    selectedState: "",
    selectedDistrict: "",
    selectedTaluka: "",
    selectedCity: "",
    link: "",
    latitude: "",
    longitude: "",
    price: "",
    review: "",
  });

  const categoryEnum = { Veg: "veg", "Non-Veg": "non-veg", Egg: "egg", Vegan: "vegan" };
  const flavorEnum = { Spicy: "spicy", Sweet: "sweet" };

  useEffect(() => {
    fetch("http://localhost:3001/states")
      .then((res) => res.json())
      .then((data) => setStates(Array.isArray(data) ? data : []))
      .catch((error) => console.error("Error fetching states:", error));
  }, []);

  // Effect to fetch city data when a city is selected
  useEffect(() => {
    if (formData.selectedCity) {
      // Get token from wherever you store it (localStorage, context, etc.)
      const token = localStorage.getItem('apiToken'); // Adjust as needed

      // Fetch city data

      // Fetch products for the selected city
      fetch(`http://localhost:3001/product?city=${encodeURIComponent(formData.selectedCity)}`)
        .then((res) => res.json())
        .then((data) => {
          setCityProducts(Array.isArray(data) ? data : []);
          console.log("City products fetched:", data);
        })
        .catch((error) => console.error("Error fetching city products:", error));
    }
  }, [formData.selectedCity]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // If user selects a dish, find and store the corresponding product
    if (name === "dishName" && cityProducts.length > 0) {
      const product = cityProducts.find(p => p.dish_name === value);
      setSelectedProduct(product || null);

      // If product is found, pre-fill other fields
      if (product) {
        setFormData(prev => ({
          ...prev,
          dishName: value,
          restaurant: product.restaurant || prev.restaurant,
          selectedCategory: Object.keys(categoryEnum).find(key =>
            categoryEnum[key] === product.foodType) || prev.selectedCategory,
          selectedFlavor: Object.keys(flavorEnum).find(key =>
            flavorEnum[key] === product.taste) || prev.selectedFlavor,
        }));
      }
    }
  };

  const selectCategory = (category) => {
    setFormData({ ...formData, selectedCategory: category });
  };

  const selectFlavor = (flavor) => {
    setFormData({ ...formData, selectedFlavor: flavor });
  };

  const onStateChange = async (e) => {
    const selectedStateName = e.target.value;
    const selectedState = states.find((state) => state.name === selectedStateName);

    setFormData((prevData) => ({
      ...prevData,
      selectedState: selectedStateName,
      selectedDistrict: "",
      selectedTaluka: "",
      selectedCity: "",
    }));

    if (selectedState) {
      try {
        const response = await fetch(`http://localhost:3001/states/${selectedState._id}/districts`);
        const data = await response.json();
        setDistricts(data);
        setTalukas([]);
        setCities([]);
      } catch (error) {
        console.error("Error fetching districts:", error);
        setDistricts([]);
        setTalukas([]);
        setCities([]);
      }
    } else {
      setDistricts([]);
      setTalukas([]);
      setCities([]);
    }
  };

  const onDistrictChange = async (e) => {
    const selectedDistrictName = e.target.value;
    const selectedDistrict = districts.find((district) => district.name === selectedDistrictName);

    setFormData((prevData) => ({
      ...prevData,
      selectedDistrict: selectedDistrictName,
      selectedTaluka: "",
      selectedCity: "",
    }));

    if (selectedDistrict) {
      try {
        const response = await fetch(`http://localhost:3001/districts/${selectedDistrict._id}/talukas`);
        const data = await response.json();
        setTalukas(data);
        setCities([]);
      } catch (error) {
        console.error("Error fetching talukas:", error);
        setTalukas([]);
        setCities([]);
      }
    } else {
      setTalukas([]);
      setCities([]);
    }
  };

  const onTalukaChange = async (e) => {
    const selectedTalukaName = e.target.value;
    const selectedTaluka = talukas.find((taluka) => taluka.name === selectedTalukaName);

    setFormData((prevData) => ({
      ...prevData,
      selectedTaluka: selectedTalukaName,
      selectedCity: "",
    }));

    if (selectedTaluka) {
      try {
        const response = await fetch(`http://localhost:3001/talukas/${selectedTaluka._id}/cities`);
        const data = await response.json();
        setCities(data);
      } catch (error) {
        console.error("Error fetching cities:", error);
        setCities([]);
      }
    } else {
      setCities([]);
    }
  };

  // Modified payload preparation
  const preparePayload = () => ({
    dish_name: formData.dishName,
    restaurant: formData.restaurant,
    state: formData.selectedState,
    city: formData.selectedCity,
    latlng: [
      parseFloat(formData.latitude) || 0.0000,
      parseFloat(formData.longitude) || 0.0000
    ],
    link: formData.link || "",
    image: "https://example.com/images/default.jpg",
    description: formData.feedback,
    foodType: categoryEnum[formData.selectedCategory] || "veg",
    taste: flavorEnum[formData.selectedFlavor] || "spicy",
    total_votes: 0,
    local_votes: 0,
    reviews: [],
  });

  // Modified submit handler to use the selected product ID for votes
  const handleSubmit = async (e) => {
    e.preventDefault();

    // If there's feedback and a selected product, submit a vote using the product ID
    if (formData.feedback.trim() && selectedProduct && selectedProduct._id) {
      try {
        const votesResponse = await fetch("http://localhost:3001/votes", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3ZThkNDEwZDczYWI3ZDc4OWMyMDI5YyIsInBob25lX251bWJlciI6IjcyNDkzNzkzMDciLCJpYXQiOjE3NDMzMTE4ODgsImV4cCI6MTc0MzkxNjY4OH0.MIEt6Dcemte3x8orn5_alpZJRI7hQT7qg00kTuc5RVQ`
          },
          body: JSON.stringify({
            productId: selectedProduct._id,
            review: formData.feedback
          }),
        });

        if (votesResponse.ok) {
          console.log("Vote submitted successfully");
          navigate("/home");
        } else {
          console.error("Error submitting vote");
        }
      } catch (error) {
        console.error("Error submitting vote:", error);
      }
    }
    // If no product is selected or there's no feedback, submit the product form
    else if (!selectedProduct) {
      const payload = preparePayload();

      try {
        const response = await fetch("http://localhost:3001/product", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3ZTgyNTZjYTc3MTU3M2RjMGJhYzY4OCIsInBob25lX251bWJlciI6Ijk4ODEwMDA1OTUiLCJpYXQiOjE3NDMyNjcyMTMsImV4cCI6MTc0Mzg3MjAxM30.Y4RteHmgQihZWlpNKYyLVsBMFkjmkWgGhDxcZT_4mSs`
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) throw new Error("Failed to submit data");
        const submittedData = await response.json();

        // If there's feedback, submit a vote using the newly created product ID
        if (formData.feedback.trim()) {
          const votesResponse = await fetch("http://localhost:3001/votes", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3ZThkNDEwZDczYWI3ZDc4OWMyMDI5YyIsInBob25lX251bWJlciI6IjcyNDkzNzkzMDciLCJpYXQiOjE3NDMzMTE4ODgsImV4cCI6MTc0MzkxNjY4OH0.MIEt6Dcemte3x8orn5_alpZJRI7hQT7qg00kTuc5RVQ`
            },
            body: JSON.stringify({
              productId: submittedData._id,
              review: formData.feedback
            }),
          });

          if (!votesResponse.ok) console.error("Warning: Votes submission failed");
        }

        console.log("Submission successful:", submittedData);
        navigate("/home");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      navigate("/home");
    }
  };

  useEffect(() => {
    if (formData.selectedCity) {
      fetch(`http://localhost:3001/product?city=${encodeURIComponent(formData.selectedCity)}`)
        .then((res) => res.json())
        .then((data) => {
          setCityProducts(data.data || []);
        })
        .catch((error) => console.error("Error fetching city products:", error));
    }
  }, [formData.selectedCity]);



  return (
    <div className="container">
      <FaTimes className="close-icon" onClick={onClose} />

      <h1>🍽️ Favorite Dish Form</h1>
      <form onSubmit={handleSubmit}>

        {step === 1 && (
          <div className="input-box">
            <label>📍 State*</label>
            <select name="selectedState" value={formData.selectedState} onChange={onStateChange}>
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state._id} value={state.name}>{state.name}</option>
              ))}
            </select>

            <label>📍 District*</label>
            <select name="selectedDistrict" value={formData.selectedDistrict} onChange={onDistrictChange} disabled={!formData.selectedState}>
              <option value="">Select District</option>
              {districts.map((district) => (
                <option key={district._id} value={district.name}>{district.name}</option>
              ))}
            </select>

            <label>📍 Taluka*</label>
            <select name="selectedTaluka" value={formData.selectedTaluka} onChange={onTalukaChange} disabled={!formData.selectedDistrict}>
              <option value="">Select Taluka</option>
              {talukas.map((taluka) => (
                <option key={taluka._id} value={taluka.name}>{taluka.name}</option>
              ))}
            </select>

            <label>📍 City*</label>
            <select name="selectedCity" value={formData.selectedCity} onChange={handleChange} disabled={!formData.selectedTaluka}>
              <option value="">Select City</option>
              {cities.map((city) => (
                <option key={city._id} value={city.name}>{city.name}</option>
              ))}
            </select>
          </div>
        )}

        {step === 2 && (
          <div className="input-box">
            <label>🍽️ Name of Dish*</label>
            {cityProducts.length > 0 ? (
              <select name="dishName" value={formData.dishName} onChange={handleChange}>
                <option value="">Select Dish</option>
                {cityProducts.map((product) => (
                  <option key={product._id} value={product.dish_name}>{product.dish_name}</option>
                ))}
              </select>
            ) : (
              <input type="text" name="dishName" value={formData.dishName} onChange={handleChange} />
            )}
          </div>
        )}

        {step === 3 && (
          <div className="input-box">
            <label>🏨 Restaurant Name*</label>
            <input type="text" name="restaurant" value={formData.restaurant} onChange={handleChange} required />
          </div>
        )}

        {/* {step === 4 && (
          <div className="input-box">
            <label>🥦🍗 Select a Category*</label>
            <div className="category-options">
              {Object.keys(categoryEnum).map((category) => (
                <div key={category} className={`category-box ${formData.selectedCategory === category ? "selected" : ""}`} onClick={() => selectCategory(category)}>
                  {category}
                </div>
              ))}
            </div>
          </div>
        )} */}

        {/* {step === 5 && (
          <div className="input-box">
            <label>🌶️ Select Flavor*</label>
            <div className="flavor-options">
              {Object.keys(flavorEnum).map((flavor) => (
                <div key={flavor} className={`flavor-box ${formData.selectedFlavor === flavor ? "selected" : ""}`} onClick={() => selectFlavor(flavor)}>
                  {flavor}
                </div>
              ))}
            </div>
          </div>
        )} */}

        {/* {step === 6 && (
          <div className="input-box">
            <label>🔗 Link (Optional)</label>
            <input type="text" name="link" value={formData.link} onChange={handleChange} />
          </div>
        )} */}

        {step === 4 && (
          <div className="input-box">
            <label>📝 Feedback (Optional)</label>
            <textarea name="feedback" value={formData.feedback} onChange={handleChange}></textarea>
          </div>
        )}

        <div className="button-container">
          {step > 1 && <button type="button" onClick={() => setStep(step - 1)}>⬅️ Previous</button>}
          {step < 4 && <button type="button" onClick={() => setStep(step + 1)}>➡️ Next</button>}
          {step === 4 && <button type="submit">🚀 Submit</button>}
        </div>
      </form>
    </div>
  );
};

export default VoteForm;