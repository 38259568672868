import React, { useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../../component/NavBar/Navbar";
import { FaUser } from "react-icons/fa";
import OtpForm from "../login/Login";
import Footer from "../../component/FooterWidget/Footer";
import VoteCard from "../../component/VoteCard/Votecard";
import StoryBox from "../../component/StoryCard/StoryCard";
import StoryBoxs from "../../component/StoryCard2/StoryCard2";
import SignIn from "../SignInGoogle/SignInGoogle";
// import Profile from "../Profile/Profile";

const Home = () => {
  const navigate = useNavigate();
  const [isLoginOpen, setIsLoginOpen] = useState(false); // State for modal

  return (
    <div className="home-container">
      {/* Header Section */}
      <div className="header-row">
        <img src="/assets/logo.png" alt="App Logo" className="logo" height={200} width={200} />
        <br />
        <div className="login-section">
          <a onClick={() => setIsLoginOpen(true)} className="login-text">Log in</a>
          <FaUser className="login-icon" onClick={() => setIsLoginOpen(true)} />
        </div>
      </div>

      {/* Navbar */}
      <NavbarComponent />

      {/* Background Section */}
      <div className="background-section">
        <div class="">

          <div>
            <h5 className="heading-text">Taste The Local Best...!</h5>
            <button className="explore-btn" onClick={() => navigate("/map")}>
             <i> Tap To Explore Map</i> 
            </button>
          </div>
        </div>


      </div>
      <br /><br />

      {/* Custom Modal */}
      {isLoginOpen && (
        <div className="modal-overlay" onClick={() => setIsLoginOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <SignIn onClose={() => setIsLoginOpen(false)} /> {/* Pass close function */}
          </div>
        </div>
      )}

      {/* Components Section */}
      <VoteCard />
      <br />
      <StoryBox />
      <br />
      <StoryBoxs />
      <br />

      
      <Footer />
        {/* <Profile /> */}
    </div>
  );
};

export default Home;
