import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom"; // For dynamic ID
import { FaStar } from "react-icons/fa";
import "./Feedback.css";

const FeedbackSection = () => {
  const { productId } = useParams(); // Get ID from URL if available
  const location = useLocation();
  const dishData = location.state?.data; // Get dish data from navigation state

  const [feedbackData, setFeedbackData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use dynamic product ID
  const finalProductId = productId || dishData?._id;

  useEffect(() => {
    if (!finalProductId) {
      setError("No Product ID found.");
      setLoading(false);
      return;
    }

    const fetchReviews = async () => {
      try {
        const response = await fetch(`http://localhost:3001/product/${finalProductId}`);
        const data = await response.json();

        if (data.statusCode === 200 && data.data.reviews) {
          setFeedbackData(data.data.reviews);
        } else {
          setFeedbackData([]);
        }
      } catch (err) {
        setError("Failed to load reviews.");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [finalProductId]);

  return (
    <div className="feedback-container">
      <h5 className="people-say fs-3 text-primary"><i>People Say...</i></h5>

      {loading ? <p>Loading reviews...</p> : null}
      {error ? <p className="error-message">{error}</p> : null}

      {feedbackData.length === 0 && !loading && !error ? (
        <p>No reviews yet. Be the first to review! 😊</p>
      ) : (
        feedbackData.map((feedback, index) => (
          <div key={index} className="feedback-card">
            <img 
              src={`https://randomuser.me/api/portraits/men/${index + 1}.jpg`} 
              alt="User" 
              className="user-img" 
            />
            <div className="feedback-content">
              <h6 className="user-name">{feedback.userId?.user_name || "FoodyGuys"}</h6>
              <p className="feedback-text">{feedback.review}</p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default FeedbackSection;
