import React from "react";
import "./Footer.css";
import { FaInstagram, FaReddit, FaXTwitter, FaYoutube, FaEnvelope } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-icons">
        <a
          href="https://www.instagram.com/foodmapof_india?igsh=OTBvaGNyM3d6bWxx"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon instagram"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.reddit.com/u/foodmapofindia/s/TMKPZZlPcA"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon reddit"
        >
          <FaReddit />
        </a>
        <a
          href="https://x.com/foodmapofindia?t=ImsKcvHXeRvvEOndXFbQaQ&s=08"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon twitter"
        >
          <FaXTwitter />
        </a>
        <a
          href="https://youtube.com/@foodmapindia?si=qun5dNP37BF34T6b"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon youtube"
        >
          <FaYoutube />
        </a>
        <a
          href="mailto:foodmapofindiacom@gmail.com"
          className="footer-icon gmail"
        >
          <FaEnvelope />
        </a>
      </div>
    </footer>
  );
};

export default Footer;