import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import MyMap from "./pages/map/Mymap";
import "bootstrap/dist/css/bootstrap.min.css";
import FoodList from "./component/CustomCard/FoodList";
import ExploreDish from "./component/ExploreDish/ExploreDish";
import VoteForm from "./component/VoteForm/VoteForm";
import VotetheDish from "./component/VotetheDish/VoteForm";
import SplashScreen from "./pages/Splash/SplashScreen";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/map" element={<MyMap />} />
        <Route path="/vote-form" element={<VoteForm />} />
        <Route path="/best-dishes" element={<FoodList />} />
        <Route path="/explore" element={<ExploreDish />} />
        <Route path="/vote-the-dish" element={<VotetheDish />} />

      </Routes>
    </Router>
  );
}

export default App;
