import React from "react";
import "./StoryCard.css"; // Import CSS file

const StoryBox = () => {
  return (
    <div className="story-container">
      <h2 className="story-title"> Why do we exist?</h2>
      <p className="story-content">
       
        We are foodie, non-foodie and no foodie giving people. But we all like to eat good wherever we go. I mean taste is a memory and when you visit any new city, you want to carry back some good experiences with you. I want to. And there are many like me, who also want to. And let's say you and your friend pack went to a new city. You step down off your SUV as if Hangover (the movie;) is being recreated. It's sunny and cool outside. Stepping into a new environment makes you alert, you want to soak in this novelty. It is energizing you.
        <p className="story-link">
          <a href="#">[Click here to read the complete story.]</a>
        </p>
      </p>
    </div>
  );
};

export default StoryBox;
