import React from "react";
import "./StoryCard2.css"; // Import CSS file

const StoryBoxs = () => {
  return (
    <div className="story-container">
      <h2 className="story-title"> How do we rank?.</h2>
      <p className="story-content">
     
      Basically you want to know what makes this map credible, As a user, you should know it all. Let me elaborate the rationale for you. The credibility of this map depends on the genuineness of YOU, the voter. Let's make it harder to vote, we thought. Now, if you have to vote, you'll have to log in using your mobile number. Unlike email iDs, a person has only one number in most cases. Thereby, we can minimise bogus email votes.
      <p className="story-link">
          <a href="#">[Click here to read the complete story.]</a>
        </p>
      </p>
    </div>
  );
};

export default StoryBoxs;
