import React, { useState } from "react"; // ✅ Import useState
import { Link } from "react-router-dom";
import { FaMap, FaVoteYea } from "react-icons/fa";
import "./Navbar.css";
import VoteForm from "../../component/VoteForm/VoteForm"; // ✅ Import VoteForm

const Navbar = () => {
  const [isVoteOpen, setIsVoteOpen] = useState(false); // ✅ Vote modal state

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <ul className="nav-links">
            <li><Link to="/map"><FaMap className="nav-icon" /> Map</Link></li>
            <li>
              <a onClick={() => setIsVoteOpen(true)} className="vote-text">
                <FaVoteYea className="nav-icon" /> Vote
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {/* ✅ Vote Modal */}
      {isVoteOpen && (
        <div className="modal-overlay" onClick={() => setIsVoteOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <VoteForm onClose={() => setIsVoteOpen(false)} /> {/* ✅ Close function pass */}
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
