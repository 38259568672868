import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../SignInGoogle/FirebaseService/firebase"; 
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaTimes } from "react-icons/fa"; // Close icon
import "./Login.css";

const OTPForm = ({ onClose }) => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpSent, setOtpSent] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const otpInputs = useRef([]);

  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: () => console.log("Recaptcha Verified"),
      });
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhone(value);
    setError(value.length === 10 ? "" : "Enter a valid 10-digit number");
  };

  const sendOtp = async () => {
    if (phone.length !== 10) return;
    setupRecaptcha();
    const appVerifier = window.recaptchaVerifier;

    try {
      const result = await signInWithPhoneNumber(auth, `+91${phone}`, appVerifier);
      setConfirmationResult(result);
      setOtpSent(true);
      toast.success("OTP Sent Successfully!", { autoClose: 3000 });
    } catch (error) {
      console.error(error);
      toast.error("Failed to send OTP. Try again.");
    }
  };

  const handleOtpChange = (index, value) => {
    if (/\d/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const verifyOtp = async () => {
    if (!confirmationResult) {
      toast.error("OTP not sent. Try again.");
      return;
    }

    try {
      await confirmationResult.confirm(otp.join(""));
      toast.success("OTP Verified Successfully!", { autoClose: 3000 });

      setTimeout(() => {
        navigate("/");
        onClose(); 
      }, 2000);

    } catch (error) {
      console.error(error);
      toast.error("Invalid OTP. Please try again.");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="card p-4 shadow-lg text-center position-relative" style={{ width: "24rem", borderRadius: "12px" }}>
        <ToastContainer />

        {/* Close Button */}
        <FaTimes className="close-icon" onClick={onClose} />

        {otpSent ? (
          <>
            <h2 className="enter-text fw-bold">Enter OTP</h2>
            <p className="text-muted">We have sent a verification code to your mobile number</p>
            <div className="d-flex justify-content-center gap-2 my-3">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  ref={(el) => (otpInputs.current[index] = el)}
                  className="otp-input"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                />
              ))}
            </div>
            <button className="btn btn-success w-100 fw-bold" onClick={verifyOtp}>
              Verify OTP
            </button>
            <p className="text-muted mt-3">
              Didn't receive the code? <span className="text-primary cursor-pointer" onClick={sendOtp}>Resend Code</span>
            </p>
          </>
        ) : (
          <>
            <h2 className="enter-text fw-bold">Enter Mobile Number :</h2>
            <input
              type="text"
              className="form-control mt-3 text-center"
              placeholder="Enter Mobile Number"
              value={phone}
              onChange={handlePhoneChange}
              maxLength="10"
            />
            {error && <p className="text-danger text-center mt-2">{error}</p>}
            <button className="btn send-otp-btn w-100 mt-3 fw-bold" onClick={sendOtp} disabled={phone.length !== 10}>
              Send OTP
            </button>
            <div id="recaptcha-container"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default OTPForm;
