import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaTimes, FaMapMarkerAlt, FaCheckCircle, FaUtensils } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./MapCard.css";

const FoodCard = ({ data, onClose }) => {
  const navigate = useNavigate();

  if (!data) return null;

  const truncatedDishName =
    data.dish_name.length > 12 ? data.dish_name.substring(0, 10) + "..." : data.dish_name;

  const handleVoteClick = () => {
    navigate("/vote-the-dish", {
      state: { productId: data._id },
    });
  };

  return (
    <div className="food-card">
      {/* Close Button */}
      <button className="close-btn" onClick={onClose}>
        <FaTimes size={20} />
      </button>

      {/* Dish Name & Restaurant */}
      <h2 className="food-title">{data.dish_name}</h2>
      <h4 className="restaurant-name">{data.restaurant}</h4>
      <p className="city-state">
        <i>{data.city}, {data.state}</i>
      </p>

      {/* Food Image */}
      <div className="image-container">
        <img src={data.image} alt={data.dish_name} className="food-img img-fluid" />
      </div>

      {/* Votes Section */}
      <div className="vote-section">
        <h6 className="fw-bold mb-0">Total Votes: <b>{data.total_votes}</b></h6>
        <p className="fst-italic mb-0">Local Votes: {data.local_votes}</p>
      </div>

      {/* Buttons */}
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{data.dish_name}</Tooltip>}>
        <Button variant="info" className="details-btn" onClick={() => navigate("/explore", { state: { data } })}>
          <FaUtensils style={{ marginRight: "5px" }} /> Explore {truncatedDishName}
        </Button>
      </OverlayTrigger>

      <div className="button-container">
        <Button className="vote-btn w-100" onClick={handleVoteClick}>
          <FaCheckCircle style={{ marginRight: "8px" }} /> Vote this dish
        </Button>
        <Button className="direction-btn w-100" onClick={() => window.open(data.link, "_blank")}>
          <FaMapMarkerAlt style={{ marginRight: "8px" }} /> Get Directions
        </Button>
      </div>
    </div>
  );
};

export default FoodCard;
