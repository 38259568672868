import React from 'react';
import { useLocation } from "react-router-dom"; 
import { Card, Button } from 'react-bootstrap';
import "./ExploreDish.css";
import FeedbackSection from '../FeedBack/Feedback';
import { FaMapMarkerAlt, FaCheckCircle } from "react-icons/fa";

const ImageSlider = () => {
  const location = useLocation();
  const data = location.state?.data;

  if (!data) return <p>Loading...</p>;

  return (
    <div className="container-fluid mt-4">
      <Card className="custom-card">  
        <div className="image-container pt-3">
        <img src={data.image} className="carousel-img" />
        {/* <img src={data.image} alt={data.dish_name} className="carousel-img" /> */}
        </div>

        <Card.Body>
          <h3 className="dish-name">{data.dish_name}</h3>
          {/* <p className='hotel-description'><i>{data.description}</i></p> */}

          <div className='address'>
            <p className="hotel-name"><i><u>{data.restaurant}</u></i></p>
            <p className="location">{data.city}, {data.state}</p>  
          </div>

          <div className="button-group">
            <Button className="vote-btn"> <FaCheckCircle /> Ate it? Vote it</Button>
            <Button className="direction-btn" onClick={() => window.open(data.link, "_blank")}>
              <FaMapMarkerAlt /> Get Direction
            </Button>
          </div>

          <Button className="vote-count-btn">
            Total Votes: {data.total_votes}
            <br />
            <span className="local-votes">Local Votes: {data.local_votes}</span>
          </Button>

          <FeedbackSection /> 
        </Card.Body>
      </Card>
    </div>
  );
};

export default ImageSlider;
