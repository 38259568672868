import React, { useState } from "react";
import { auth, provider, signInWithPopup, db } from "../SignInGoogle/FirebaseService/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SignInGoogle.css";
import { FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 

const SignIn = ({ onClose }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const handleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            console.log("User Info:", result.user);

            const userRef = doc(db, "users", result.user.uid);
            const userSnap = await getDoc(userRef);

            if (userSnap.exists()) {
              
                toast.info("Already Logged In!", { autoClose: 2000 });
            } else {
                
                await setDoc(userRef, {
                    uid: result.user.uid,
                    name: result.user.displayName,
                    email: result.user.email,
                    profilePic: result.user.photoURL,
                    createdAt: new Date(),
                });

                toast.success("Login Successful!", { autoClose: 2000 });
            }

            setIsAuthenticated(true); 
        } catch (error) {
            console.error("Error Signing In:", error);
            toast.error("Login Failed! Try Again.");
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
            <ToastContainer position="top-right" />
            <div className="card shadow-lg p-4 text-center">
                {/* Close Button */}
                <FaTimes className="close-icon" onClick={onClose} />

                {/* Google Logo */}
                <img
                    src="/assets/logo.png"
                    alt="Google Logo"
                    className="network-image mx-auto"
                    height={200}
                    width={200}
                />

                {/* Card Body */}
                <div className="card-body">
                    <h5 className="card-title">Sign In to become</h5>
                    <p className="card-title"><i> ambassador of your city</i></p>

                    {/* Google Sign-In Button */}
                    <button className="btn sign-btn d-flex align-items-center justify-content-center gap-2 w-100" onClick={handleSignIn}>
                       
                       [Google Sign In Goes Here]
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
