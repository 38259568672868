import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./VotetheDish.css";

const VotetheDish = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [formData, setFormData] = useState({
    dishName: "N/A",
    selectedCategory: "N/A",
    selectedFlavor: "N/A",
    selectedState: "N/A",
    selectedDistrict: "N/A",
    selectedTaluka: "N/A",
    selectedCity: "N/A",
    link: "N/A",
    price: "N/A",
    feedback: "",
    userReview: "",
  });

  const productId = location.state?.productId || new URLSearchParams(location.search).get("id");

  useEffect(() => {
    if (!productId) {
      setError("No product ID provided");
      setLoading(false);
      return;
    }

    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`http://localhost:3001/product/${productId}`);
        if (!response.ok) throw new Error("Failed to fetch product details");
        const productData = await response.json();

        setProductDetails(productData);

        setFormData((prevData) => ({
          ...prevData,
          dishName: productData.dish_name || "N/A",
          selectedCategory: productData.foodType || "N/A",
          selectedState: productData.state || "N/A",
          selectedCity: productData.city || "N/A",
          selectedFlavor: productData.taste || "N/A",
          selectedDistrict: productData.district || "N/A",
          selectedTaluka: productData.taluka || "N/A",
          link: typeof productData.link === "string" ? productData.link : productData.link?.[0] || "N/A",
          price: productData.price ? productData.price.toString() : "N/A",
        }));

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handleChange = (e) => setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.userReview || !productId) {
      alert("Please fill in all required fields.");
      return;
    }
    try {
      const votingPayload = { productId, review: formData.userReview };
      const voteResponse = await fetch("http://localhost:3001/votes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3ZTZlZTVlNmJhMjBkNTAzZDExODBiZCIsInBob25lX251bWJlciI6Ijk4ODEwMDA1OTQiLCJpYXQiOjE3NDMxODc1ODgsImV4cCI6MTc0Mzc5MjM4OH0.M44GrbkPjVurm1OzbgAviqW1ZtJGog1JHiQ7EJqCpzA`,
        },
        body: JSON.stringify(votingPayload),
      });

      if (!voteResponse.ok) {
        const errorData = await voteResponse.json();
        throw new Error(errorData.message || "Failed to submit vote");
      }
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        navigate("/");
      }, 3000);
    } catch (error) {
      alert(error.message || "Failed to submit vote. Please try again.");
    }
  };

  if (loading) return <div>Loading product details...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container">
      <h1>🍽️ Favorite Dish Form</h1>
      <form onSubmit={handleSubmit}>
        <div className="input-box">
          <label>📝 Review (Optional)</label>
          <textarea name="userReview" value={formData.userReview} onChange={handleChange}></textarea>
        </div>
        <div className="button-container">
          <button type="submit">Submit</button>
        </div>
      </form>

      {showSuccessPopup && (
        <div className="success-popup">
          <div className="success-popup-content">
            <h2>✅ Vote Submitted Successfully!</h2>
            <p>Thank you for your review.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VotetheDish;
