import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Firestore Import

const firebaseConfig = {
  apiKey: "AIzaSyB6kldhAP0sVYCFC7lK2MJHoqm8KZVOlnw",
  authDomain: "foodmapindia-a3a90.firebaseapp.com",
  projectId: "foodmapindia-a3a90",
  storageBucket: "foodmapindia-a3a90.firebasestorage.app",
  messagingSenderId: "6987609808",
  appId: "1:6987609808:web:cd70f57831c7b2cc3b90d5",
  measurementId: "G-TK38BZRZR4"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app); // Firestore DB

export { auth, provider, signInWithPopup, signOut, db };
